<template>
<div class="main">
  <div class="main_right">
    <VxeBasicTable ref="xGrid" :gridOptions="gridOptions" :formOptions="formOptions" :searchOptions="searchOptions">
    </VxeBasicTable>
  </div>
</div>
</template>

<script>
export default {
  data() {
    return {
      gridOptions: {
        // 列表功能按钮组
        toolbarConfig: {
          export: true,
          refresh: {
            query: () => {
              this.$refs.xGrid.getLists();
            },
          },
        },
        // 列表字段配置
        columns: [{
            type: "seq",
            width: 60,
          },
          {
            type: "checkbox",
            width: 60,
          },
          {
            field: "name",
            title: "企业名称",
            minWidth: 160,
            align: "left",
            editRender: {
              name: "$input",
              props: {
                maxlength: 24,
              }
            },
          },
          {
            field: "creditCode",
            title: "统一社会信用代码",
            minWidth: 160,
            editRender: {
              name: "$input",
              props: {
                maxlength: 24,
              }
            },
          },
          {
            field: "shortName",
            title: "简称",
            minWidth: 160,
            editRender: {
              name: "$input",
              props: {
                maxlength: 24,
              }
            },
          },
          {
            field: "type",
            title: "类型",
            minWidth: 160,
            editRender: {
              name: "$select",
              optionTypeCode: "enterpriseType",
              props: {
                placeholder: "请选择企业类型",
              },
            },
          },
          {
            field: "orderCode",
            title: "排序",
            width: 100,
            editRender: {
              name: "$input",
              props: {
                maxlength: 24,
              }
            },
          },
          {
            field: "enabled",
            title: "状态",
            width: 120,
            cellRender: {
              name: "$switch",
              optionTypeCode: "enabledStatus",
              props: {
                openLabel: "启用",
                closeLabel: "禁用",
                openValue: "1",
                closeValue: "0"
              },
              events: {
                change: ({ row }) => {
                  this.$refs.xGrid.submitEvent(row);
                }
              }
            },
          },
          {
            field: "updateTime",
            title: "更新日期",
            width: 200,
          },
          {
            fixed: "right",
            title: "操作",
            width: 160,
            editRender: {
              name: "$VxeGridButtons"
            },
          },
        ],
        treeConfig: {
          children: "children",
          accordion: false,
          expandAll: true,
        },
        editConfig: {},
        exportConfig: {},
        importConfig: {},
      },
      formOptions: {
        // 新增功能字段配置
        width: 800,
        titleWidth: 100,
        items: [{
            field: "name",
            title: "企业名称",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入企业名称",
              },
            },
          },
          {
            field: "shortName",
            title: "企业简称",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入企业简称",
              },
            },
          },
          {
            field: "type",
            title: "类型",
            span: 12,
            itemRender: {
              name: "$select",
              optionTypeCode: "enterpriseType",
              props: {
                placeholder: "请选择企业类型",
              },
            },
          },
          {
            field: "creditCode",
            title: "信用代码",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                maxlength: "18",
                placeholder: "请输入统一社会信用代码",
              },
            },
          },
          {
            field: "legalPersonName",
            title: "企业法人",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入企业法人",
              },
            },
          },
          {
            field: "legalPersonPhone",
            title: "联系电话",
            span: 12,
            itemRender: {
              name: "$VxePhoneInput",
              props: {
                maxlength: "11",
                placeholder: "请输入联系电话",
              },
            },
          },
          {
            field: "status",
            title: "审核状态",
            span: 12,
            itemRender: {
              name: "$select",
              optionTypeCode: "enabledStatus",
              props: {
                placeholder: "请选择审核状态",
              },
            },
          },
          {
            field: "enabled",
            title: "状态",
            span: 12,
            itemRender: {
              name: "$select",
              optionTypeCode: "enabledStatus",
              props: {
                placeholder: "请选择状态",
              },
            },
          },
          {
            field: "orderCode",
            title: "排序",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                type: "integer",
                maxlength: "3",
                placeholder: "请输入排序号",
              },
            },
          },
          {
            field: 'remark',
            title: '备注',
            span: 24,
            itemRender: {
              name: '$textarea',
              props: {
                showWordCount: true,
                maxlength: "300",
                resize: "none",
                placeholder: '请输入备注内容'
              }
            }
          },
          {
            align: "center",
            span: 24,
            itemRender: {
              name: "$buttons",
              children: [{
                  props: {
                    type: "submit",
                    content: "提交",
                    status: "primary",
                  },
                },
                {
                  props: {
                    type: "reset",
                    content: "取消",
                  },
                },
              ],
            },
          },
        ],
        //表单校验规则
        rules: {
          parentId: [
            { required: true, message: "请选择所属上级" },
          ],
          name: [
            { required: true, message: "请输入企业名称" },
            { min: 2, max: 30, message: "长度在 2 到 30 个字符" },
          ],
          creditCode: [
            { required: true, message: "请输入统一社会信用代码" },
            { min: 18, max: 18, message: "长度为18为的数字和字母" },
          ],
          code: [
            { required: true, message: "请输入企业代码" },
            { min: 1, max: 3, message: "长度在 1 到 3 个字符" },
          ],
          type: [
            { required: true, message: '请选择类型' },
          ],
          enabled: [{ required: true, message: "请选择状态" }],
        },
        // 新增功能字段初始化
        data: {
          parentId: "1",
          name: "",
          type: "2",
          orderCode: "0",
          enabled: "1",
          status: "",
        },
      },

      // 查询功能字段配置
      searchOptions: {
        items: [{
            field: "name",
            title: "企业名称",
            itemRender: {
              name: "$input",
              props: {
                placeholder: "请输入企业名称",
              },
            },
          },
          {
            align: "center",
            itemRender: {
              name: "$buttons",
              children: [{
                  props: {
                    type: "submit",
                    content: "查询",
                    status: "primary",
                  },
                },
                {
                  props: {
                    type: "reset",
                    content: "重置",
                  },
                },
              ],
            },
          },
        ],
        searchProps: ['name', 'creditCode', 'shortName'],
        data: {
          status: "1",
          name: "",
          enabled: "",
        },
      },

    };
  },
};
</script>

<style scoped>
</style>
